import 'jquery';
import 'bootstrap';
import 'jquery-colorbox';
;

import Router from './util/Router';
import common from './routes/common';
import singleProduct from './routes/singleProduct';
import home from './routes/home';

import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faAt, faSearch, faClose, faVolumeUp, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
library.add(faAt, faSearch, faFacebook, faInstagram, faYoutube, faClose, faFilePdf, faVolumeUp, faPlayCircle);
dom.watch();

const routes = new Router({
  common, home, singleProduct,
});

jQuery(document).ready(() => routes.loadEvents());
