import { WOW } from 'wowjs';
export default {
  init() {
    /* Search Form Header */
    $('#menu_header_search_menu a.nav-link').click(function () {
      $('#header_search_modal').addClass('search-open')
    })
    $('#search_close').click(function () {
      $('#header_search_modal').removeClass('search-open')
    })
    /* Hambuger Click */
    function open_menu() {
      $('button.hamburger').toggleClass('is-active')
      $('#mainmenucontent').toggleClass('show')
      $('body').toggleClass('is-lock-scroll')
    }
    $('button.hamburger').click(function () {
      open_menu()
    })
    /* Fix Navbar on Top */
    var header_nav = $('.header-nav')
    var header_logo_h = 150
    $(window).scroll(function () {
      if ($(this).scrollTop() > header_logo_h) {
        header_nav.addClass('fixed-top')
      } else {
        header_nav.removeClass('fixed-top')
      }
    });
    /* Animation on Scroll */
    new WOW().init();
    /* Colorbox */
    $('a.colorbox').colorbox();
    /* Dropdown Product serach */
    var dropdown = document.getElementById('sidebar-cats-mobile-list');
    function onCatChange() {
      if (dropdown.options[dropdown.selectedIndex].value) {
        location.href = '/cat/' + dropdown.options[dropdown.selectedIndex].value;
      }
      else {
        location.href = '/';
      }
    }
    if (dropdown) {
      dropdown.onchange = onCatChange;
    }
  },
  finalize() {
  },
};
