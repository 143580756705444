import Splide from '@splidejs/splide';
export default {
  init() {
  },
  finalize() {

    if ($('.splide.products-splide').length) {
      new Splide('.splide.products-splide', {
        type: 'loop',
        perPage: 5,
        perMove: 1,
        breakpoints: {
          1024: {
            perPage: 4,
          },
          992: {
            perPage: 3,
          },
          768: {
            perPage: 2,
          },
          576: {
            perPage: 1,
          },
        },
      }).mount();
    }
    if ($('.splide.artisti-splide').length) {

      new Splide('.splide.artisti-splide', {
        type: 'loop',
        perPage: 4,
        perMove: 1,
        autoplay: true,
        breakpoints: {
          992: {
            perPage: 3,
          },
          768: {
            perPage: 2,
          },
          576: {
            perPage: 1,
          },
        },
      }).mount();
    }
  },
};
